import { Account, Venue } from "@models/models";
import { amplifyGet } from "@utils/http";
import { Field } from "@measured/puck";

const field: Field<Account | undefined> = {
  type: "external",
  label: "Account",
  showSearch: true,
  getItemSummary: (item: Account) => item.name,
  mapRow: (item: Account) => ({
    Name: item.name,
  }),
  fetchList: async ({ query }) => {
    const result = await amplifyGet("/api/accounts");
    const accounts = result.data as Account[];
    return !query
      ? accounts
      : accounts.filter((p) => p.name.toLowerCase().includes(query));
  },
};

export default field;
