import { useGetLiveViewComponentPreviewMutation } from "@store/adsSlice";
import { useEffect, useState } from "react";

export default function LiveViewComponentPreview({ config }: { config: any }) {
  const [getLiveViewComponentPreview] =
    useGetLiveViewComponentPreviewMutation();
  const [html, setHtml] = useState<string>("hi there");
  useEffect(() => {
    getLiveViewComponentPreview(config).then((result) => {
      setHtml(result?.data?.data as unknown as string);
    });
  }, [config]);
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
}
