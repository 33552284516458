import { Config, DefaultRootProps } from "@measured/puck";
import { UpcomingEvents } from "@components/puck/page-builder/components/upcoming-events";
import React from "react";
import { SubscribeToMailingList } from "@components/puck/page-builder/components/subscribe-to-mailing-list";
import { ComicProfile } from "@components/puck/page-builder/components/comic-profile";
import { FlexColumns } from "@components/puck/page-builder/components/flex-columns";
import { RichText } from "@utils/puck/components/rich-text";
import { NotifyVenue } from "@components/puck/page-builder/components/notify-venue";
import { BrandLogo } from "@components/puck/page-builder/components/brand-logo";
import { VerticalSpace } from "@utils/puck/components/vertical-space";
import { Container } from "@components/puck/page-builder/components/container";
import { Search } from "@components/puck/page-builder/components/search";
import { RedeemTickets } from "@components/puck/page-builder/components/redeem-tickets";
import { PlayContest } from "@components/puck/page-builder/components/play-contest";
import { Passes } from "@components/puck/page-builder/components/passes";
import { GiftCards } from "@components/puck/page-builder/components/gift-cards";
import { DigitalProductComponent } from "@components/puck/page-builder/components/product";
import { WebImage } from "@components/puck/page-builder/components/image";
import { EventProductComponent } from "@components/puck/page-builder/components/event";
import { EventList } from "@components/puck/page-builder/components/event-list";

const config: Config = {
  components: {
    EventList,
    ProductComponent: DigitalProductComponent,
    EventComponent: EventProductComponent,
    UpcomingEvents,
    Passes,
    GiftCards,
    SubscribeToMailingList,
    ComicProfile,
    Container,
    FlexColumns,
    WebImage,
    RichText,
    NotifyVenue,
    BrandLogo,
    VerticalSpace,
    Search,
    RedeemTickets,
    PlayContest,
  },

  root: {
    fields: {
      title: { type: "text", label: "Page Title" },
      description: { type: "textarea", label: "Description" },
      keywords: { type: "text", label: "Keywords" },
    },
    render: ({ children }: any) => {
      return (
        <div
          style={{
            padding: "10px",
            margin: "auto",
          }}
        >
          {children}
        </div>
      );
    },
  },
};

export default config;
