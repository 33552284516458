import { Venue } from "@models/models";
import { amplifyGet } from "@utils/http";
import { Field } from "@measured/puck";

const field: Field<Venue | undefined> = {
  type: "external",
  label: "Venue",
  showSearch: true,
  getItemSummary: (item: Venue) => item.name,
  mapRow: (item: Venue) => ({
    Name: item.name,
    Address: `${item.address} ${item.province} ${item.country}`,
  }),
  fetchList: async ({ query }) => {
    const result = await amplifyGet("/api/accounts");
    const venues = result.data as Venue[];
    return !query
      ? venues
      : venues.filter((p) => p.name.toLowerCase().includes(query));
  },
};

export default field;
