import { CalendarEvent } from "../models/models";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import EmptyListState from "./empty-list-state";
import * as React from "react";
import Image from "next/image";
import dayjs from "dayjs";
import { useRouter } from "next/navigation";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { isMobile } from "react-device-detect";
import { formatDate, isUtcShowtimeInFuture } from "@jumpcomedy/utils";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import Link from "next/link";
import _ from "lodash";

dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

type CalendarEventsProps = {
  events: CalendarEvent[];
  constructOnSelectUrl: (c: CalendarEvent) => string;
};

const EventDisplay = ({ event }: { event: CalendarEvent }) => {
  return (
    <div className={"flex gap-x-1 items-center"}>
      <Image
        className={"hidden md:block rounded"}
        src={event.thumbnail}
        alt={event.title}
        width={30}
        height={30}
      />
      <div className={"text-sm text-blue-800 font-bold hover:cursor-pointer"}>
        {event.title}
      </div>
    </div>
  );
};

const EventDisplayAgenda = ({ event }: { event: CalendarEvent }) => {
  return (
    <div className={"flex gap-x-2 items-center"}>
      <Image
        className={"rounded"}
        src={event.thumbnail}
        alt={event.title}
        width={50}
        height={50}
      />
      <div className={"flex flex-col gap-x-1"}>
        <div className={"text-md text-blue-800 font-bold hover:cursor-pointer"}>
          {event.title}
        </div>
        <div className={"text-md text-gray-500"}>
          {formatDate(event.startsAt)}
        </div>
      </div>
    </div>
  );
};
const EventDisplayMonth = ({ event }: { event: CalendarEvent }) => {
  return (
    <div>
      <Image
        className={"hidden md:block float-right p-0"}
        src={event.thumbnail}
        alt={event.title}
        width={30}
        height={30}
      />
      <div className={"text-xs text-white whitespace-normal"}>
        <div className={"font-bold"}>{event.startTime}</div>
        <div>{event.title}</div>
      </div>
    </div>
  );
};
function fromUtcToTimezoneDate(str: string, timezone: string): Date {
  // @ts-ignore
  const tzDate = dayjs(str).tz(timezone);
  return new Date(
    tzDate.year(),
    tzDate.month(),
    tzDate.date(),
    tzDate.hour(),
    tzDate.minute(),
  );
}

export default function CalendarEvents({
  events,
  constructOnSelectUrl,
}: CalendarEventsProps) {
  const router = useRouter();

  return (
    <>
      {events && events.length !== 0 && (
        <>
          {isMobile ? (
            <div className={"flex flex-col"}>
              {_.sortBy(
                events.filter((e) => isUtcShowtimeInFuture(e.start)),
                (e) => e.startsAt,
              ).map((event, idx) => (
                <Link
                  href={constructOnSelectUrl(event)}
                  key={idx}
                  className={"py-2 border-b border-gray-300 "}
                >
                  <EventDisplayAgenda event={event} />
                </Link>
              ))}
            </div>
          ) : (
            <div className={"overflow-visible"} style={{ minHeight: 1000 }}>
              <Calendar
                localizer={localizer}
                events={events}
                startAccessor={(d: any) =>
                  fromUtcToTimezoneDate(d.start, d.timezone)
                }
                endAccessor={(d: any) =>
                  fromUtcToTimezoneDate(d.end, d.timezone)
                }
                components={{
                  event: EventDisplay,
                  month: { event: EventDisplayMonth },
                  agenda: { event: EventDisplayAgenda },
                }}
                style={{ height: 500 }}
                timeslots={2}
                defaultView={"month"}
                min={new Date(1972, 0, 1, 16, 0, 0)}
                max={new Date(1972, 0, 1, 23, 59, 59)}
                onSelectEvent={(e: CalendarEvent) =>
                  router.push(constructOnSelectUrl(e))
                }
              />
            </div>
          )}
        </>
      )}
      {events && events.length === 0 && (
        <EmptyListState list={events} message={"No events exist."} />
      )}
    </>
  );
}
