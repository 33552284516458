import React from "react";

import { ComponentConfig } from "@measured/puck";
import { Account, EventDisplayMode, Venue } from "@models/models";
import { Message } from "@components/message";
import venue from "@components/puck/page-builder/fields/venue";
import SelectPlatformId from "@components/puck/page-builder/components/select-platform-id";
import LiveViewComponentPreview from "@components/puck/page-builder/data-components/live-view-component-preview";
import account from "../fields/account";

export type EventProps = {
  venue?: Venue;
  account?: Account;
  platform?: number;
  source: "account" | "venue" | "platform";
  show_calendar_option: boolean;
  default_display_view: EventDisplayMode;
  brand_id: number;
};

export const EventList: ComponentConfig<EventProps> = {
  label: "Event List (Beta)",
  fields: {
    brand_id: {
      type: "radio",
      label: "Brand",
      options: [
        {
          label: "Default",
          value: localStorage.getItem("brand_id") as any as number,
        },
      ],
    },
    show_calendar_option: {
      type: "radio",
      label: "Show Calendar Option",
      options: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
    },
    default_display_view: {
      type: "radio",
      label: "Default Display View",
      options: [
        { label: "Calendar", value: "calendar" },
        { label: "Images", value: "list" },
      ],
    },
    source: {
      type: "select",
      label: "Source",
      options: [
        { label: "Account", value: "account" },
        { label: "Platform", value: "platform" },
        { label: "Venue", value: "venue" },
      ],
    },
    venue,
    account,
    platform: {
      type: "custom",
      render: ({ onChange }) => (
        <SelectPlatformId onSelectPlatform={onChange} />
      ),
    },
  },
  defaultProps: {
    source: "platform",
    show_calendar_option: false,
    default_display_view: EventDisplayMode.LIST,
    brand_id: localStorage.getItem("brand_id") as any as number,
  },
  render: ({
    venue,
    account,
    platform,
    source,
    show_calendar_option,
    default_display_view,
  }: EventProps) => {
    console.log("source, account", source, account);
    if (source == "venue" && !venue) {
      return error(
        "You have selected Venue as the source, so please select a venue.",
      );
    }
    return (
      <LiveViewComponentPreview
        config={{
          type: "EventList",
          props: {
            id: "some_id",
            source,
            account,
            platform,
            show_calendar_option,
            default_display_view,
            brand_id: localStorage.getItem("brand_id"),
          },
        }}
      />
    );
  },
};

function error(message: string) {
  return (
    <Message message={message} title={"Invalid Values"} type={"warning"} />
  );
}
